(function($){
    function mediaFit($parent, ratio) {
        var calcRatio = null;
        var parent = $parent[0];
        var $inner = $parent.find('.media-fit__inner');
        function fit(objectRatio, parent){
            var containerWidth = parent.clientWidth;
            var containerHeight = parent.clientHeight;
            var containerRatio = containerWidth / containerHeight;
            var objectHeight, objectWidth, objectX, objectY;
            if (objectRatio > containerRatio) { // wider than parent
                objectHeight = containerHeight;
                objectWidth = objectHeight * objectRatio;
                objectY = 0;
                objectX = (containerWidth - objectWidth) / 2;
            }
            else { // taller than parent
                objectWidth = containerWidth;
                objectHeight = objectWidth / objectRatio;
                objectX = 0;
                objectY = (containerHeight - objectHeight) / 2;
            }
            return {width: objectWidth, height: objectHeight, left: objectX, top: objectY};
        }

        function resizeEventHandler() {
            if (!ratio && !calcRatio) {
                var obj = parentRef.querySelector('img, video, picture, iframe');
                obj.style.width = '100px';
                obj.style.height = 'auto';
                calcRatio = obj.offsetWidth / obj.offsetHeight;
                obj.style.width = '';
                obj.style.height = '';
            }
            var metrics;
            if (calcRatio)
                metrics = fit(calcRatio, parent);
            else if (ratio)
                metrics = fit(ratio, parent);
            $inner.css(metrics);
            $parent.addClass('js-loaded');

        };
        window.addEventListener('resize', resizeEventHandler);
        setTimeout(() => { resizeEventHandler(); }, 50)
        setTimeout(() => { resizeEventHandler(); }, 100)
        setTimeout(() => { resizeEventHandler(); }, 500)
        setTimeout(() => { resizeEventHandler(); }, 1000)
        setTimeout(() => { resizeEventHandler(); }, 1500)
        setTimeout(() => { resizeEventHandler(); }, 2500)
    }


    $(document).ready(function(){
        var $root = $('.home-walk');
        var $triangles = $('.home-walk__triangles');
        var $pointsContainer = $('.home-walk__scroll-points');
        var $points = $('.home-walk__scroll-point');
        var $pointsTracker = $('<ul class="home-walk__scroll-points-tracker"></ul>');


        $('.media-fit').each(function(){
            mediaFit($(this), 16/9);
        });


        $points.each(function(){
            var $tracker = $('<li></li>');
            $pointsTracker.append($tracker);
            $tracker.on('click', () => {
                var screenH = $(window).height();
                window.scrollTo({top: screenH * ($tracker.index() + 1), left:0, behavior: 'smooth'});
            });
        });
        $pointsTracker.find('li:last-child').remove();




        $pointsContainer.prepend($pointsTracker);
        let currentSection = 0;
        $(window).on('scroll', function(){
            var screenH = $(window).height();
            // var docH = $points.last().offset().top + $points.last().height();
            var docH = $(document).height();
            var position = $(window).scrollTop() - (screenH / 2.3);
            var section = Math.floor(position/(docH/(docH/screenH))) + 1;
            if(section !== currentSection){
                // $triangles.css({'transform': 'translate3d(0,'+section*screenH+'px, 0)'});
                $root.attr('data-current-section', section);
                currentSection = section;
            }
        });


        var $zoomPoints = $('.home-walk__zoom-point');
        var $zoomPointContainer = $('.home-walk__zoom-points-container');

        $zoomPointContainer.css({height: ($(window).height() * $zoomPoints.length) * 1.5})


        var screenH = $(window).height();
        var zoomPntTl = gsap.timeline({
            delay: 0,
            paused: true,
            repeat: 0,
            defaults: {
                duration: 1,
                ease: "none"
            },
            scrollTrigger: {
                trigger: "body",
                scrub: 1,
                pin: false,
                start: $zoomPointContainer.offset().top + (screenH / 2),
                end: $zoomPointContainer.offset().top + $zoomPointContainer.height() - $zoomPoints.eq(0).height() - (screenH/3),
                onToggle: function(){console.log('Toggled!')},

            }
        });


        $zoomPoints.each(function(idx){
            $(this).css({'z-index': $zoomPoints.length - idx});
            var $pointA = $(this).find('.home-walk__zoom-point-a');
            var $pointB = $(this).find('.home-walk__zoom-point-b');
            zoomPntTl
                .addLabel('point-'+idx)
                .to(this, { duration: 1, z:60, autoAlpha:1}, '<-=0.1')
                .to(this, { duration: 1, z:0, autoAlpha:1})
                .to($pointA[0], { duration: 0.5, y:-100, autoAlpha:0}, '-=1')
                .to($pointB[0], { duration: 1, y:0, autoAlpha:1}, '-=1')
                .to($pointB[0], { duration: 1.5, y:-30, autoAlpha:1})

            if(idx !== $zoomPoints.length - 1){
                zoomPntTl.to(this, { duration: 1, z:500, autoAlpha:0, filter: 'blur(50px)' })
            } else {
                zoomPntTl.delay(5)
                zoomPntTl.to(this, { duration: 1, z:500, autoAlpha:0, filter: 'blur(50px)' })
            }
        });

        $('.home-walk__scroll-point-follow li').on('click', (e) => {
            var index = $(e.currentTarget).index() + 1;
            var screenH = $(window).height();
            var start = $zoomPointContainer.offset().top;
            window.scrollTo({
                top: start + (screenH * index) + (screenH * 0.2),
                behavior: 'smooth'
            });
        });
        
        $('.home-walk__next-action').on('click', (e) => {
            var screenH = $(window).height();
            var currentScreenY = Math.ceil((window.scrollY - (screenH / 2)) / screenH);
            console.log(currentScreenY);
            window.scrollTo({
                top: screenH * (currentScreenY + 1),
                behavior: 'smooth'
            });
        });

        var heroVideo = $('.home-walk video')[0];
        heroVideo.onplay = function (){
            $('.home-walk').attr('data-intro-stage', 2);
        }

        setTimeout(function(){
            if(parseInt($('.home-walk').attr('data-intro-stage'), 10) === 0){
                $('.home-walk').attr('data-intro-stage', 1);
            }
        }, 500);

        setInterval(function(){
            if(parseInt($('.home-walk').attr('data-intro-stage'), 10) === 5) return;
            if(heroVideo.currentTime > 12){
                $('.home-walk').attr('data-intro-stage', 5);
            } else if(heroVideo.currentTime > 6){
                $('.home-walk').attr('data-intro-stage', 4);
            } else if (heroVideo.currentTime > 4) {
                $('.home-walk').attr('data-intro-stage', 3);
            }
        }, 100);


    });
})(jQuery);